import React, {Component} from 'react';
import Speech from "speak-tts"

class SpeechButton extends Component {
    constructor(props) {
        super(props);
        this.state = {}
        this.handleSpeech = this.handleSpeech.bind(this);

        if(this.props.media){
            this.url = "/ademon/media/"+this.props.media;
            this.audio = new Audio(this.url);
        }

        this.speaker_main = (this.props.main);

        this.play = this.play.bind(this);
        this.pause = this.pause.bind(this);

    }

    play(){
        this.setState({
            play: true,
            pause: false
        });
        if(this.audio!==undefined) {
            console.log(this.audio);
            this.audio.pause();
            this.audio.currentTime=0;
            this.audio.play();
        }
    }

    pause(){
        this.setState({ play: false, pause: true });
        this.audio.pause();
    }


    handleSpeech() {

        this.play();

        return;

        const speech = new Speech();

        if (speech.hasBrowserSupport()) { // returns a boolean
            console.log("speech synthesis supported");
            speech.init({
                'volume': 1,
                //'lang': 'en-GB',
                'rate': 1,
                'pitch': 1,
                //'voice': 'Google UK English Female',
                'splitSentences': true,
            }).then((data) => {
                console.log("Speech is ready", data);

                speech.speak({
                    text: this.props.text,
                }).then(() => {
                    console.log("Success !")
                    alert(this.props.text);
                }).catch(e => {
                    alert("An error occurred : "+e)
                });

            }).catch(e => {
                console.error("An error occured while initializing : ", e)
                alert("An error occured while initializing : " + e)
            })
        } else {
            console.log('no browser support');
            alert('no browser support');
        }
    }

    render() {
        if(!this.props.media){
           if(!this.speaker_main) {
               return (
                   <div style={{"width": "10%"}} />
                   )
           }
            return ""
        }
        let className = "speaker" + (this.speaker_main?"--main":"");
        return (
            <div className={className} onClick={this.handleSpeech}>
            </div>
        );
    }
}

export default SpeechButton;
