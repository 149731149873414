import React, {Component} from 'react';


class StopCodeScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: 7,
            activeTimer: false,
            link:'',
            message:'',
        };
        // rozparsovani www a zpravy
        let msg = this.state.message = this.props.message;
        if(msg.startsWith('www.') && msg.indexOf('|') > 0 && this.props.stopcode >= 5){
            this.state.link = msg.substring(0,msg.indexOf('|'));
            this.state.message = msg.substring(msg.indexOf('|')+1);
        }

        this.handleContinue = this.handleContinue.bind(this);
        this.handleLeave = this.handleLeave.bind(this);


        let sc = parseInt(this.props.stopcode)

        if (sc === 1) {
            this.runTimer()
        }
    }

    handleContinue() {
        this.props.btnContinue();
    }

    handleLeave() {
        this.props.btnLeave();
    }

    runTimer() {
        setTimeout(() => {
            let newValue = this.state.timer - 1;
            this.setState({'timer': newValue})
            if (newValue < 1) {
                this.handleContinue()
            } else {
                this.runTimer()
            }
        }, 1000)

    }


    render() {
        return (
            <div className="App">
                <div className="stopcode">
                    <header className="message">
                        {this.state.message}
                    </header>

                    {this.controls()}

                    {this.finalactions()}

                </div>
            </div>
        );
    }

    finalactions(){
        if(this.props.stopcode>=5){
            // zobrazi bud odkaz "DETAIL" nebo tlacitko "AGAIN"
            if(this.state.link) {
                let link = 'https://' + this.state.link;
                return (
                    <div className="finalaction">
                        <div className={"btn-again"}>
                            <a href={link} style={{'color':'white'}}>detail</a>
                        </div>
                    </div>
                );
            }else{
                return (
                    <div className="finalaction">
                        <div className={"btn-again"}>
                            <a onClick={function(){ window.location.reload(); return false;}}>again?</a>
                        </div>
                    </div>
                );
            }
        }
        return "";
    }
    controls() {

        let sc = parseInt(this.props.stopcode)

        if (sc === 1) {
            // set timeout.. a pri nule  this.handleContinue
            return (
                    <div className="controls">
                        <div class={"btn-timer bg-color-dark"}>
                            <div className={"timer radius-top-right bg-color-light"}>
                                {this.state.timer}
                            </div>
                        </div>
                    </div>
            )
        }
        if (sc === 2) {
            return (
                    <div className="controls">
                        <div className="btn-continue">
                            <button className="continue radius-bottom-right-none" onClick={this.handleContinue}>continue</button>
                        </div>
                    </div>
            )
        }
        if (sc === 3) {
            return (
                <div className="controls">
                    <div className="btn-continue">
                        <button className="continue" onClick={this.handleContinue}>continue</button>
                    </div>
                    <div className="btn-leave">
                        <button className="leave" onClick={this.handleLeave}>leave</button>
                    </div>
                    <div className="block-dark" />
                </div>
            )
        }
        if (sc === 4) {
            return (
                <div className="controls">
                    <div className="btn-leave bg-color-dark">
                        <button className="leave bg-color-light radius-top-left-none radius-bottom-left-none radius-top-right" onClick={this.handleLeave}>leave</button>
                    </div>
                </div>
            )
        }
    }
}

export default StopCodeScreen;
