import React, {Component} from 'react';


class Frame extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: 0,
            fcolor: 0,
            falert: 0,
            falertid: 0,
            frameAlertClass: '',
            active: false,
        };

        if (this.props.data) {
            if (this.props.data.FALERT) {
                this.state.timer = 1;
                this.state.fcolor = this.props.data.FCOLOR;
                this.state.falert = parseInt(this.props.data.FALERT);
                this.state.falerid = parseInt(this.props.data.FALERID);
                this.state.active = true;
            }
        }

        if (this.state.falert > 0) {
            this.runTimer()
        }
    }

    runAlert() {
        if (!this.state.falerid || this.state.stressAlertClass) {
            return;
        }

        if (this.state.timer > this.state.falert) {
            console.log('alert')
            if (this.state.falerid === 1) {
                this.setState({stressAlertClass: 'red'});
                this.props.handleFrameAlert('red');
            }
            if (this.state.falerid === 2) {
                this.setState({stressAlertClass: 'red-alert'});
                this.props.handleFrameAlert('red-alert');
            }

        }
    }

    runTimer() {
        if (!this.state.active || this.props.disabled)
            return;

        this.timetout = setTimeout(() => {
            if (!this.state.active || this.props.disabled || this.state.stressAlertClass)
                return;
            let newValue = this.state.timer + 1;
            this.setState({'timer': newValue});
            this.runAlert();
            if (newValue < 1) {
                this.handleAnswer()
            } else {
                this.runTimer()
            }
        }, 1000)

    }

    componentWillUnmount() {
        clearTimeout(this.timetout)
    }

    render() {
        return "";
    }
}

export default Frame;
