import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';

ReactDOM.render(<App />, document.getElementById('root'));

// test komentar g
// test koment 18.11.2021 after Jira
// test rozsireny koment 18.11.2021


