import React, {Component} from 'react';


class FinalScreen extends Component {
    render() {
        return (
            <div className="App">
                <header className="FinalScreen message">
                    {this.props.message}
                </header>
            </div>
        );
    }
}

export default FinalScreen;
