import React, {Component} from 'react';
import './App.css';
import LoginScreen from "./Screens/LoginScreen";
import FinalScreen from "./Screens/FinalScreen";
import StopCodeScreen from "./Screens/StopCodeScreen";
import DialogScreen from "./Screens/DialogScreen";
import LoadingScreen from "./Screens/LoadingScreen";
import './custom.css';

class App extends Component {
    constructor(props) {
        super(props);


        this.url = '/mdemon/mdemon.py';

        this.urllog = '/log.php';

        // log
        fetch(this.urllog,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify({'action':'load'})
            })

        // vychozi stav aplikace
        this.state = {
            page: 'login',
            screen_counter:0,
            error: false,
            errorMessage: null,
            loading: false,
            appid: '',
            appcode: '',
            user: {'SNAME': null, 'SNICK': null, 'TGENDER': null, 'SGENDER': null, 'SMAIL': null, 'STEL': null},
            stopcode: 0,
            logrow: {},
            appdata_old: {},
            appdata: {
                'TESTER': {
                    "TEMOTIKONID": 1,
                    'TTEXT': {
                        'TCONTENT': 'Jaký může také ukazuje, že mají ',
                        'TLOOK':'SPEAK',
                    },
                    'TSPKID':'3Tasdfasf'
                },
                "PERSONA": {
                    "PEMOTIKONID": 1,
                    "PTEXT": {
                        "ANSWER": [
                            {
                                "AID": "1",
                                'ACONTENT': 'Jaký může',
                                'ATYPE': 'P',
                                'ACOLOR': 1,
                                'ASPKID': '1asdfghjkl'
                            },
                            {
                                "AID": "2",
                                "ACONTINUE": "2",
                                'ATYPE': 'P',
                                'ACONTENT': 'Jaký může být vysledek hry? Nejvíce hra přitahovala VZTAHOVÉ ŠÉFY, kteří jsou ve ',
                                'ACOLOR': 2,
                                'ASPKID': '2asdfghjkl'
                            },
                            {
                                "AID": "3",
                                "ACONTINUE": "2",
                                'ATYPE': 'P',
                                'ACONTENT': 'Jaký může být vysledek hry? Nejvíce hra přitahovala VZTAHOVÉ ŠÉFY, kteří jsou ve ',
                                'ACOLOR': 3
                            },
                            {
                                "AID": "4", 'ATYPE': 'P',
                                "ACONTINUE": "2",
                                'ACONTENT': 'Jaký může být vysledek hry? Nejvíce hra přitahovala VZTAHOVÉ ŠÉFY, kteří jsou ve ',
                                'ACOLOR': 4
                            },
                        ]
                    }
                },
                "STRESS": {
                    "STIME": 100,
                    'SALERT': 20,
                    'SALERID': 2
                },
                "FRAME": {
                    "FCOLOR": 100,
                    'FALERT': 20,
                    'FALERID': 1
                },
                'STOPCODE': 0, // nemenit
                'STOPERR': 'Hru jste úspěšně dokončili ' +
                    '\n',
            },
            idcontext:'',
        };

        this.handleLogin = this.handleLogin.bind(this);
        this.handleAnswer = this.handleAnswer.bind(this);
        this.request = this.request.bind(this)
        this.handleLeave = this.handleLeave.bind(this)
        this.handleContinue = this.handleContinue.bind(this)
        this.sendProtocol = this.sendProtocol.bind(this)
    }

    handleLogin(id, nick, email, tgender, idcontext) {
        // set context
        this.setState({idcontext:idcontext});

        // login
        let user = this.state.user;

        user['SNICK'] = nick;
        user['SMAIL'] = email;
        if(tgender.trim() !== ''){
            user['TGENDER'] = tgender;
        }

        this.setState({appid: id, user: user},
            function () {
                this.sendProtocol()
            });
    }

    sendProtocol() {
        console.log(this.state)
        let protocol = {
            'APP': {'APPID': this.state.appid, 'APPCODE': this.state.appcode},
            'USER': this.state.user,
            'LR': this.state.logrow,
            'SC': this.state.stopcode,
        };
        this.request(protocol)
    }

    handleAnswer(aid, atp, aco) {
        console.log('answer', aid, atp, aco)

        let lr = {
            'AID': aid,
            'ATP': atp,
            'ACO': aco
        };
        let that = this;
        this.setState({logrow: lr}, function () {
            if (this.state.appdata && this.state.appdata.STOPCODE > 0) {

                if (parseInt(that.state.appdata.STOPCODE) === 5) {

                    this.setState({stopcode: 5}, function () {
                        console.log('stopcode5');
                        that.sendProtocol()
                    })
                } else {
                    this.setState({page: 'stopcode'});
                }
            } else {
                that.sendProtocol()
            }
        })
    }

    handleContinue() {
        let screensc = this.state.appdata.STOPCODE;
        let that = this;
        this.setState({stopcode: screensc}, function () {
            console.log('continue');
            that.sendProtocol()
        })
    }

    handleLeave() {
        let that = this;
        this.setState({stopcode: 6}, function () {
            console.log('leave');
            that.sendProtocol();
        })
    }


    request(request) {

        this.setState({loading: true});
        console.log(request);


        fetch(this.url,
            {
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                },
                method: "POST",
                body: JSON.stringify(request)
            })
            .then(res => res.json())
            .then(
                (result) => {
                    console.log(result);

                    if (result.APPCODE || result.STOPCODE) {

                        let nextpage = this.nextPageDialogOrStopcode(result)

                        this.setState({
                            screen_counter: this.state.screen_counter+1,
                            appdata: result,
                            appcode: result.APPCODE,
                            page: nextpage,
                            stopcode: 0,
                            logrow: {},
                            loading: false,
                        })
                    } else {
                        this.setState({
                            error: true,
                            errorMessage: 'Error',
                            loading: false,
                        });
                    }
                },
                (error) => {
                    console.log(error);

                    this.setState({
                        error: true,
                        errorMessage: 'Network error or incorrect data from server',
                    });
                }
            )

    }

    renderAcivePage() {
        if (this.state.error) {
            return (
                <FinalScreen message={this.state.errorMessage}/>
            )
        }

        if (this.state.page === 'loading') {
            return (
                <LoadingScreen/>
            )
        }
        if (this.state.page === 'login') {
            return (
                <LoginScreen handleLogin={this.handleLogin}/>
            )
        }
        if (this.state.page === 'dialog') {
            console.log(this.state.appdata)
            return (
                <DialogScreen data={this.state.appdata} handleAnswer={this.handleAnswer} key={this.state.screen_counter} idcontext={this.state.idcontext}/>
            )
        }
        if (this.state.page === 'stopcode') {
            let sc = this.state.appdata.STOPCODE;
            let msg = this.state.appdata.STOPERR;
            return (
                <StopCodeScreen message={msg} stopcode={sc}
                                btnContinue={this.handleContinue} btnLeave={this.handleLeave}/>
            )
        }
    }

    render() {
        return (
            <>

                {this.renderAcivePage()}

                <LoadingScreen show={this.state.loading}/>
            </>
        );
    }

    nextPageDialogOrStopcode(result) {

        console.log((!result.TESTER || !result.TESTER.TEMOTIKONID || parseInt(result.TESTER.TEMOTIKONID) === 0))
        if (
            (!result.TESTER || !result.TESTER.TEMOTIKONID || parseInt(result.TESTER.TEMOTIKONID) === 0)
            &&
            (!result.PERSONA || !result.PERSONA.PEMOTIKONID || parseInt(result.PERSONA.PEMOTIKONID) === 0)
        ) {
            return 'stopcode'
        }

        return 'dialog'


    }

}

export default App;
