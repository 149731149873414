import React, {Component} from 'react';
import Background from '../assets/login.jpg';
import FacebookLogin from 'react-facebook-login';


class LoginScreen extends Component {

    constructor(props) {
        super(props);
        this.state = {
            appid: '',
            nick: '',
            email: '',
            tgender: '',
            hideAppId: false,
            hideEmail: false,
            hideFbLogin: false,
            title: 'Hello! Let\'s play :)',
            idcontext: '',
        };

        this.handleEncryptedInput();
        this.handleLogin = this.handleLogin.bind(this);
        this.onChangeEmail = this.onChangeEmail.bind(this);
        this.onChangeAppId = this.onChangeAppId.bind(this);
    }


    handleEncryptedInput(){

        // string after last slash
        let input = window.location.pathname.split('/').slice(-1).join('/');

        input = input.split('_').join('+');
        input = input.split('-').join('/');

        var CryptoJS = require("crypto-js");

        // Decrypt
        try{
            var jsondata = CryptoJS.AES.decrypt(input, 'JLJxR5ucQnMGVEB').toString(CryptoJS.enc.Utf8);
            if(!jsondata){
                return;
            }
        }catch(err){
            return;
        }

        var data = JSON.parse(jsondata)

        if(!data){
            return;
        }
        console.log(data);

        // pokud je vk, nastavit
        if(data.vk.trim().length>0){
            this.state.appid = data.vk;
            this.state.hideAppId = true;
        }
        // pokud je snick, nastavit
        if(data.sn.trim().length>0){
            this.state.nick = data.sn;
            this.state.hideFbLogin = true;
        }
        // pokud je smail, nastavit
        if(data.sm.trim().length>0){
            this.state.email = data.sm;
            this.state.hideEmail = true;
            this.state.hideFbLogin = true;
        }
        // pokud je title, nastavit
        if(data.t.trim().length>0){
            this.state.title = data.t;
        }

        // pokud je idcontext, nastavit
        if(data.ctx.trim().length>0){
            this.state.idcontext = data.ctx;
        }
        // pokud je tgender, nastavit
        if(data.tg.trim().length>0){
            this.state.tgender = data.tg;
        }

        this.state.encryptedinput = true;

    }

    handleLogin() {
        if(this.state.appid && this.state.email) {
            this.props.handleLogin(this.state.appid, this.state.nick, this.state.email,this.state.tgender,this.state.idcontext);
        }
    }

    onChangeEmail(event) {
        this.setState({'email': event.target.value})
        if(!this.state.encryptedinput){
                this.setState({'nick': 'EMAIL'})
        }
    }
    onChangeAppId(event) {
        this.setState({'appid': event.target.value})
    }

    responseFacebook = (response) => {
        console.log(response);

        if(response.email && response.id) {
            this.setState({nick: 'FB:' + response.id, email: response.email});
            this.handleLogin();
        }
    }

    render() {
        let style={
            backgroundImage:'url('+Background+')'
        }


        var appIdInput = <p><label>
             <input name="appid" placeholder="app ID:" value={this.state.appid}
                 onChange={this.onChangeAppId}/>
             </label></p>;

        var emailInput =<p><label>
            <input name="email" placeholder="e-mail:"
                value={this.state.email}
                onChange={this.onChangeEmail}/>
            </label></p>;

        var fbLoginButton = <FacebookLogin
            appId="724768705143994"
            autoLoad={false}
            fields="name,email"
            textButton="OR LOGIN WITH FACEBOOK"
            cssClass=""
            callback={this.responseFacebook} />

        if(this.state.encryptedinput){
            if(this.state.hideAppId){
                appIdInput = '';
            }
            if(this.state.hideFbLogin){
                fbLoginButton = '';
            }
            if(this.state.hideEmail){
                emailInput = '';
            }
        }


        return (
            <div className="App login" style={style}>

                <div className="login">
                    <header className={"min-height-200 align-itemsend"}>
                        {this.state.title}
                    </header>
                    <p><label>
                        <span style={{"backgroundColor": "#7884B3",
                            "color": "#0e0d33",
                            "display": "block",
                            "width": "auto",
                            "borderTopRightRadius": "20px",
                            "borderBottomRightRadius": "20px",
                            "borderTopLeftRadius": "0px",
                            "borderBottomLeftRadius": "0px"}}><br /></span>
                    </label></p>
                    {appIdInput}
                    {emailInput}

                    <p>
                        <button onClick={this.handleLogin}>ENTER</button>
                    </p>
                    <p style={{'fontWeight':'bold','textAlign':'center','padding':'10px'}}></p>

                    {fbLoginButton}

                </div>
            </div>
        );
    }
}

export default LoginScreen;
