import React, {Component} from 'react';


class LoadingScreen extends Component {
    render() {
        if(!this.props.show){
            return'';
        }
        return (

                <div className="loading">
                    <div className="spinner">
                        <div className="lds-default">
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                            <div></div>
                        </div>
                    </div>
                </div>

        );
    }
}

export default LoadingScreen;
