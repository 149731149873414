import React, {Component} from 'react';


class Stress extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: 0,
            stime: 0,
            salert:0,
            salertid:0,
            stressAlertClass:'',
            active:false,
        };

        if (this.props.data) {
            if (this.props.data.STIME) {
                this.state.timer=this.state.stime=parseInt(this.props.data.STIME);
                this.state.salert=parseInt(this.props.data.SALERT);
                this.state.salerid=parseInt(this.props.data.SALERID);
                this.state.active=true;
            }
        }

        this.handleAnswer = this.handleAnswer.bind(this);

        if (this.state.timer > 0) {
            this.runTimer()
        }
    }

    handleAnswer() {
        if(this.props.disabled){
            return;
        }
        this.props.handleAnswer('')
    }

    runAlert(){
        if(!this.state.salerid || this.state.stressAlertClass){
            return;
        }
        let time = this.state.stime - this.state.timer;
        if(time>this.state.salert){

            if(this.state.salerid===1){
                this.setState({stressAlertClass:'red'})
            }
            if(this.state.salerid===2){
                this.setState({stressAlertClass:'red-alert'})
            }

        }
    }
    runTimer() {
        if(!this.state.active || this.props.disabled)
            return;

        this.timetout=setTimeout(() => {
            if(!this.state.active || this.props.disabled)
                return;
            let newValue = this.state.timer - 1;
            this.setState({'timer': newValue});
            this.runAlert();
            if (newValue < 1) {
                this.handleAnswer()
            } else {
                this.runTimer()
            }
        }, 1000)

    }

    componentWillUnmount(){
        clearTimeout(this.timetout)
    }

    render() {
        if(!this.state.active)
            return"";
        let width = Math.round((this.state.timer/this.state.stime)*100);

        let stressClass='stressline';
        if(this.state.stressAlertClass){
            stressClass+=' '+this.state.stressAlertClass;
        }
        return (
            <div className="stress-box">
                <div className="timer">
                    <div className={stressClass} style={{width:width+'%',position:'absolute',right:0}}>
                    </div>
                </div>
            </div>
        );
    }
}

export default Stress;
