import React, {Component} from 'react';
import './EmoticonImg.css'

class Emoticon extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    render() {
        let id = parseInt(this.props.id);

        if(id<1){
            //return;
        }
        if(id<10){
            id='0'+id;
        }

        return (
            <div className={"emoticon-box emoticon-"+id} />
        );
    }
    renderOld() {
        let id = this.props.id;

        if(id<1){
           return;
        }
        if(id<10){
            id='0'+id;
        }

        return (
            <div className="emoticon-box">

                <div className={"emoticon emoticon-"+id}>
                    <div className="head">
                        <div className="eye-l"></div>
                        <div className="eyebrow-l"></div>
                        <div className="eye-r"></div>
                        <div className="eyebrow-r"></div>
                        <div className="mouth"></div>
                    </div>

                    <div className="body">
                        <div className="hand-l">
                            <div className="hand-part"></div>
                        </div>
                        <div className="hand-r">
                            <div className="hand-part"></div>
                        </div>
                    </div>


                </div>
            </div>
        );
    }
}

export default Emoticon;
