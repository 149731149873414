import React, {Component} from 'react';
import SpeechButton from "./SpeechButton";

class Answer extends Component {
    constructor(props) {
        super(props);
        this.state = {
            'aco':'',
            'disabled':false,
        };
        this.handleAnswer = this.handleAnswer.bind(this);
        this.handleKeyPress = this.handleKeyPress.bind(this);
    }
    handleAnswer(){

        let aid = this.props.data['AID'];
        let atp = this.props.data['ATYPE'];
        let aco = this.state.aco;
        this.props.handleAnswer(aid,atp,aco)
    }
    handleKeyPress(e){
        if(this.state.disabled || this.props.disabled)
            return;

        if (e.key === 'Enter') {
            let that = this;
            this.setState({aco:e.target.value, disabled:true}, function(){
                that.handleAnswer()
            })
        }
    }
    render() {
        let data = this.props.data;
        let className=(data.ACOLOR?' acolor'+data.ACOLOR:'');

        let atype=data['ATYPE'];


        if(this.props.disabled && !this.props.selected){
            className+=' inactive';
        }else if(this.props.disabled && this.props.selected){
            className+=' active';
        }
        if(atype==='P') {

            let speaker = data.ASPKID?<SpeechButton media={data.ASPKID} text={data.ACONTENT} /> : <div style={{"width": "10%"}} onClick={this.handleAnswer} />;

            return (
                <div className={"answer" + className}>
                    {speaker}
                    <div className="acontent" onClick={this.handleAnswer}>
                        {data.ACONTENT}
                    </div>
                    <div className={"answer--btn"} onClick={this.handleAnswer} />
                </div>
            );
        }else if(atype==='A') {
            return (
                <div className="active-answer">
                    <textarea className="aco" name="aco" placeholder="Text:" autoFocus={true}
                           onKeyPress={this.handleKeyPress}
                           ref={input => input && input.focus()}
                           disabled={this.state.disabled}/>
                </div>
            );
        }else{
            return ""
        }
    }
}

export default Answer;
